export default {
    methods: {
        pagination(paginate, currentPage) {
            const lastPage = paginate.last_page_id;
            const firstPage = 1;
            let pagesIndexes = [];
            
            if (lastPage == firstPage) {
                pagesIndexes = [{
                    id: 1,
                    isFirst: true,
                    isLast: true
                }];
            }

            if (lastPage > firstPage) {
                let index = currentPage;
                if (index == lastPage - 1) {
                    pagesIndexes.push({
                        id: index == 1 ? 1 : index - 1,
                        isFirst: firstPage == index,
                        isLast: false
                    });
                }
                while(index < lastPage - 1 && pagesIndexes.length < 2) {
                    pagesIndexes.push({
                        id: index,
                        isFirst: firstPage == index,
                        isLast: pagesIndexes.length == lastPage - 2
                    });
                    index++;
                }

                if (lastPage > 4 && pagesIndexes.length < 3 ) {
                    pagesIndexes.push({
                        id: lastPage - 1,
                        isFirst: false,
                        isLast: false
                    });
                }

                if (lastPage == 3 && pagesIndexes.length < 3 ) {
                    pagesIndexes.push({
                        id: lastPage - 1,
                        isFirst: false,
                        isLast: false
                    });
                }

                if ( pagesIndexes.length < lastPage && pagesIndexes.length < 4 ) {
                    pagesIndexes.push({
                        id: lastPage,
                        isFirst: false,
                        isLast: true
                    });
                }

                if ( pagesIndexes[0].id > 5) {
                    pagesIndexes.unshift({
                        id: 2,
                        isFirst: true,
                        isLast: false
                    });
                    pagesIndexes.unshift({
                        id: 1,
                        isFirst: true,
                        isLast: false
                    });
                }

            }

            return {
                pagesLinks: pagesIndexes,
                isFirstPage: paginate?.onFirstPage,
                isLastPage: !paginate?.hasMorePages,
                lastPageID: lastPage
            }
        },
    }
};