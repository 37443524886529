import { API, logError } from "./index";

const getHomeSpecialty = async () => {

  try {
    return await API().get(`/home-specialties`);
  } catch (error) {
    return logError(error);
  }
};

const getSingleSpecialty = async (ID) => {

  try {
    return await API().get(`/specialty/${ID}`);
  } catch (error) {
    return logError(error);
  }
};

const getAllSpecialty = async () => {

  try {
    return await API().get(`/all-specialties`);
  } catch (error) {
    return logError(error);
  }
};


const getSpecialtyModules = async (params) => {

  let URL = `/speciality-module?speciality_id=${params.specialityID}`;
  if (params.sort) URL = `${URL}&sort=${params.sort}`;

  try {
    return await API().get(URL);
  } catch (error) {
    return logError(error);
  }
};


export {
  getHomeSpecialty,
  getSingleSpecialty,
  getAllSpecialty,
  getSpecialtyModules
};