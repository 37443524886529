<template>
    <div class="about">
        <div class="page-head about">
            <h1 class="page-title therapeutic font-weight-bold text-uppercase">
                Therapeutic Area
            </h1>
        </div>
        <div class="page-content">
            <div class="container">
                <div class="features-items row align-items-center">
                    <template v-if="loadingSpecilty">
                        <span
                        v-for="item in 10"
                        :key="item"
                        class="
                            col-6 col-lg-4 col-xl-3
                            mb-4
                            d-flex
                            flex-column
                            align-items-center
                            justify-content-center
                        "
                    >
                        <b-skeleton class="img-circle mb-3" width="4rem" height="4rem"></b-skeleton>
                        <b-skeleton width="9rem" height="1rem"></b-skeleton>
                    </span>
                    </template>
                    <template v-else-if="!loadingSpecilty && specialties && specialties.length">
                        <router-link
                            v-for="(specialty, index) in specialties"
                            :key="index"
                            :to="`/therapeutic-area/${ specialty.id }`"
                            class="
                                col-6 col-lg-4 col-xl-3
                                mb-4
                                d-flex
                                flex-column
                                align-items-center
                                justify-content-center
                            "
                        >
                            <img
                                :src="specialty.image"
                                :alt="specialty.name"
                                class="mb-3"
                            />
                            <span class="text-color">{{ specialty.name }}</span>
                        </router-link>
                    </template>
                    <div v-else class="w-100 d-flex justify-content-center">
                        <div class="no-modules py-1 px-3">
                            {{ $t('noResults') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TopFooterAds />
    </div>
</template>

<script>
import TopFooterAds from "@/components/TopFooterAds";
import { getAllSpecialty } from "@/API/specialties";

export default {
    name: "TherapeuticHome",
    components: {
        TopFooterAds,
    },
    data() {
        return {
            loadingSpecilty: true,
            specialties: [],
        }
    },
    methods: {
        allSpecialties() {
            getAllSpecialty().then(res => {
                if (res.status == 200) {
                    const response = res.data?.data?.specialty;
                    // console.log(res.data);
                    this.specialties = response || [];
                }
                this.loadingSpecilty = false;
            })
        }
    },
    mounted() {
        this.allSpecialties()
    }
};
</script>
